import React from 'react';
import Navigate from '../navigate';
import cx from 'classnames';

function CategoryTitleBordered (props) {
  const className = cx(
    'top-border-category relative border-t-default font-montserrat border-gray-400 font-semibold pt-2 text-denim text-2xl break-word leading-tight',
    props.className,
    props.className?.includes('mb') ? '' : 'mb-6',
    props.className?.includes('mt') ? '' : 'mt-2'
  );

  const render = () => {
    return <div className={className} data-datocms-noindex>
      {props.title}
    </div>;
  };

  const breadCrumb = () => {
    return <div className={className} data-datocms-noindex>
      <Navigate href={props.parentLink} className={'text-base lg:text-2xl'}>
        {props.parentTitle}
      </Navigate>
      <span className={'category-breadcrumb'}/>
      <Navigate href={props.link} className={'text-base lg:text-2xl'}>
        {props.title}
      </Navigate>
    </div>;
  };

  const navigation = () => {
    if (props.parentTitle) {
      return breadCrumb();
    }
    return <Navigate href={props.link}>
      {render()}
    </Navigate>;
  };

  return <>
    {props.link
      ? navigation()
      : render()
    }
  </>;
}

export default CategoryTitleBordered;
